import { H2 } from '@repo/ui/components/Typography.js'
import { cn } from '@repo/ui/utils/utils'
import type { ComponentProps } from 'react'
import { Illustrations } from '../illustrations'
import { useTableContext } from './useTableContext'

export const DataTableEmptyState = ({
  title,
  description,
  illustration,
  className,
  ...props
}: ComponentProps<'div'> & {
  illustration: keyof typeof Illustrations
  title: React.ReactNode
  description?: React.ReactNode
  action?: React.ReactNode
}) => {
  const { data } = useTableContext()

  if (data.rowCount) return null

  return (
    <div
      className={cn(
        'w-full h-full rounded-xl flex flex-col items-center justify-center p-12 min-h-96 gap-6 border',
        className
      )}
      {...props}
    >
      <div className="w-40">{Illustrations[illustration]()}</div>
      <div className="space-y-2 text-center max-w-md">
        <H2 className="font-medium text-lg">{title}</H2>
        {description ? (
          <p className="text-muted-foreground text-sm">{description}</p>
        ) : null}
      </div>
    </div>
  )
}
